ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	background-color: #333;
	position: fixed;
 	top: 0;
  	width: 100%;
	z-index: 100;
}
  
li {
	float: left;
}
  
li a {
	display: block;
	color: white;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
}
  
  /* Change the link color to #111 (black) on hover */
li a:hover {
	background-color: #111;
}
.connect-button{
	float: right;
}
.connect-button button{
	height: 45px;
	background-color: #333;
	color: white;
	border: transparent;
	text-decoration: none;
	text-align: center;
	padding: 14px 16px;
	display: inline-flex;
}
.connect-button button:hover{
	background-color: #111;
}
.balance{
	position: inherit;
	float: right;
	background-color: #333;
	color: white;
	border: transparent;
	text-decoration: none;
	text-align: right;
	padding: 14px 16px;
	display: inline-flex;
}
