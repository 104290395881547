.now-button{
    cursor: pointer;
    border: 1px solid #b4b8bc;
    background-color: transparent;
    height: 56px;
    width: 56px;
    color: black;
    border-radius: 10%;
}
.dater{
    margin: 0;
	padding: 0;
	overflow: hidden;
	background-color: white;
    top: 45px;
	position: fixed;
  	width: 100%;
	z-index: 99;
    border: 1px solid #b4b8bc;
}
